import React from "react";
import { AppConstant } from "../../../../AppConstant";

const SideService = () => {
  return (
    <div class="col-lg-4">
      <div class="tp-services-details-wrap mb-40">
        <div class="tp-services-details-services mb-50">
          <h4 class="tp-services-details-services-title">Services</h4>
          <div class="tp-services-details-services-wrap">
            <a href="#">
              Cloud Service <i class="fa-light fa-arrow-right"></i>
            </a>
            <a href="#">
              App Development <i class="fa-light fa-arrow-right"></i>
            </a>
            <a href="#">
              Ui/Ux Designing <i class="fa-light fa-arrow-right"></i>
            </a>
          </div>
        </div>
        <div class="tp-services-details-contact">
          <div
            class="tp-services-details-contact-bg"
            style={{
              background:
                "url(assets/img/services/details/services-details-bg-1.jpg)",
            }}
          ></div>
          <h5 class="tp-services-details-contact-title">
            Have Any Problem? Please, Contact
          </h5>
          <div class="tp-services-details-contact-item d-flex align-items-center mb-25">
            <div class="tp-services-details-contact-item-icon">
              <i class="fa-light fa-phone"></i>
            </div>
            <div class="tp-services-details-contact-item-content">
              <span>Call Us</span>
              <a href={`tel:${AppConstant.CALL.number}`}>+91 {AppConstant.CALL.number}</a>
            </div>
          </div>
          <div class="tp-services-details-contact-item d-flex align-items-center mb-25">
            <div class="tp-services-details-contact-item-icon">
              <i class="fa-sharp fa-light fa-envelope"></i>
            </div>
            <div class="tp-services-details-contact-item-content">
              <span>Write to Us</span>
              <a href={`mailto:${AppConstant.EMAIL.id}`}>{AppConstant.EMAIL.title}</a>
            </div>
          </div>
          <div class="tp-services-details-contact-item d-flex align-items-center">
            <div class="tp-services-details-contact-item-icon">
              <i class="fa-sharp fa-light fa-location-dot"></i>
            </div>
            <div class="tp-services-details-contact-item-content">
              <span>Our Address</span>
              <a href="#">Vadodara</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideService;

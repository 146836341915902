import React from 'react'
import CountUp from 'react-countup';
const CounterSection = () => {
  return (
    <section class="counter-area tp-counter-3-bg" style={{background:'url(assets/img/counter/counter-3-bg-1.jpg)'}}>
            <div class="container">
               <div class="row">
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-project-management"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="3480"  class="purecounter">
                                 <CountUp start={0} end={20} duration={1} />
                              </span>
                           </h3>
                           <p>Project Completed</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-consultant"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="15"  class="purecounter">
                                 <CountUp start={0} end={15} duration={1} />
                              </span>
                           </h3>
                           <p>IT Specialist</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-healthcare"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="20"  class="purecounter">
                                 <CountUp start={0} end={20} duration={1} />
                              </span>
                           </h3>
                           <p>Happy Clients </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-solution-1"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="256"  class="purecounter">
                                 <CountUp start={0} end={256} duration={1} />
                              </span>
                           </h3>
                           <p>Smart Solution </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
  )
}

export default CounterSection
import React from "react";

const ManagementSection = () => {
  return (
    <section class="management-area tp-management-position pb-60">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-xl-6 col-lg-6">
            <div class="tp-management-thumb-wrap p-relative">
              <div
                class="tp-management-thumb overlay-anim tp-thumb-common fix w-img mb-60 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="tp-thumb-common-overlay wow"></div>
                <img src="assets/img/thumbs/management-thumb-1.jpg" alt="" />
              </div>
              <div class="tp-management-shape-1">
                <img src="assets/img/shape/management-shape-1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-xl-5 offset-xl-1 col-lg-6">
            <div
              class="tp-management-wrap pb-60 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div class="tp-section mb-40">
                {/* <span class="tp-section-sub-title">Tech Management</span> */}
                <h4 class="tp-section-title">
                  Let’s Build Your Success With MetaFox
                </h4>
                <div class="tp-section-title-wrapper">
                  <p>
                    The concentration of our company lies in the ability to understand the business processes of the client. We partner with our clients and add value to their business.
                  </p>
                  <span>
                    We recognize the goals and visions of them beyond the segments.
                  </span>
                </div>
              </div>
              <div class="tp-management-list">
                {/* <div class="tp-management-item d-flex">
                  <div class="tp-management-item-icon">
                    <i class="flaticon-data-protection"></i>
                  </div>
                  <div class="tp-management-item-content">
                    <h5 class="tp-management-item-title">Data Protection</h5>
                    <p>
                      We’ve designed a culture that allows our <br /> stewards
                      to assimilate
                    </p>
                  </div>
                </div> */}
                <div class="tp-management-item d-flex">
                  <div class="tp-management-item-icon">
                    <i class="flaticon-optimization"></i>
                  </div>
                  <div class="tp-management-item-content">
                    <h5 class="tp-management-item-title">
                      Optimized Software Solution
                    </h5>
                    <p>
                      We closely stand by our services, and we are fully responsible for each of the codes that we write for our clients, so your security is our core responsibility.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManagementSection;

import React from "react";

const ServiceSteps = () => {
  return (
    <section class="step-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="tp-step-wrapper d-flex justify-content-between">
              <div
                class="tp-step-item text-center mb-30"
                data-aos="zoom-in"
                data-aos-duration="600"
                data-aos-delay="600"
              >
                <div class="tp-step-item-count">
                  <span>1</span>
                </div>
                <div class="tp-step-item-overlay p-relative mb-30">
                  <div class="tp-step-item-thumb">
                    <img src="assets/img/thumbs/step/step-thumb-1.png" alt="" />
                  </div>
                  <div class="tp-step-item-thumb-icon">
                    <i class="flaticon-design"></i>
                  </div>
                </div>
                <div class="tp-step-item-content">
                  <h4 class="tp-step-item-title">Research</h4>
                  <p>
                    Requirement gathering and market research
                  </p>
                </div>
              </div>
              <div
                class="tp-step-item tp-step-item-even text-center mb-30"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="500"
              >
                <div class="tp-step-item-count">
                  <span>2</span>
                </div>
                <div class="tp-step-item-overlay p-relative mb-30">
                  <div class="tp-step-item-thumb">
                    <img src="assets/img/thumbs/step/step-thumb-2.png" alt="" />
                  </div>
                  <div class="tp-step-item-thumb-icon">
                    <i class="flaticon-design"></i>
                  </div>
                </div>
                <div class="tp-step-item-content">
                  <h4 class="tp-step-item-title">Design</h4>
                  <p>
                    Prepare attractive UI as per business needs
                  </p>
                </div>
              </div>
              <div
                class="tp-step-item text-center mb-30"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="500"
              >
                <div class="tp-step-item-count">
                  <span>3</span>
                </div>
                <div class="tp-step-item-overlay p-relative mb-30">
                  <div class="tp-step-item-thumb">
                    <img src="assets/img/thumbs/step/step-thumb-3.png" alt="" />
                  </div>
                  <div class="tp-step-item-thumb-icon">
                    <i class="flaticon-design"></i>
                  </div>
                </div>
                <div class="tp-step-item-content">
                  <h4 class="tp-step-item-title">Development</h4>
                  <p>
                    Make UX and Perform quality assured test
                  </p>
                </div>
              </div>
              <div
                class="tp-step-item tp-step-item-even text-center mb-30"
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="600"
              >
                <div class="tp-step-item-count">
                  <span>4</span>
                </div>
                <div class="tp-step-item-overlay p-relative mb-30">
                  <div class="tp-step-item-thumb">
                    <img src="assets/img/thumbs/step/step-thumb-4.png" alt="" />
                  </div>
                  <div class="tp-step-item-thumb-icon">
                    <i class="flaticon-design"></i>
                  </div>
                </div>
                <div class="tp-step-item-content">
                  <h4 class="tp-step-item-title">Production</h4>
                  <p>
                    Luanch it to Moon
                  </p>
                </div>
              </div>
              <div class="tp-step-line-shape d-none d-xl-block">
                <img
                  src="assets/img/thumbs/step/step-line-shape-1.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSteps;

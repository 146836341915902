import React from "react";

const FeatureSection = () => {
  return (
    <section
      class="feature-area pt-120 pb-90 p-relative feature-inner-bg"
      style={{
        background: "url(assets/img/feature/inner/feature-inner-bg-1.jpg)",
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div
              class="tp-feature-item mb-30 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div class="tp-feature-icon">
                <i class="flaticon-solution-1"></i>
              </div>
              <div class="tp-feature-content">
                <h4 class="tp-feature-content-title">
                  Best Business <br /> Solution
                </h4>
                <p>
                  We offers a full-cycle software development services that meet business{" "}
                </p>
              </div>
              <div class="tp-feature-shape-two">
                <img src="assets/img/feature/one/feature-shape-1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="tp-feature-item mb-30 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div class="tp-feature-icon">
                <i class="flaticon-search-engine"></i>
              </div>
              <div class="tp-feature-content">
                <h4 class="tp-feature-content-title">
                  Business Growth <br /> Planning
                </h4>
                <p>
                  We aimed to develop your software needs that will take your business to top-notch level
                </p>
              </div>
              <div class="tp-feature-shape-two">
                <img src="assets/img/feature/one/feature-shape-1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="tp-feature-item mb-30 wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div class="tp-feature-icon">
                <i class="flaticon-speed"></i>
              </div>
              <div class="tp-feature-content">
                <h4 class="tp-feature-content-title">
                  24/7 Support
                </h4>
                <p>
                  We understand your business needs that sometime required crucuial support from IT team and we have promised on it.
                </p>
              </div>
              <div class="tp-feature-shape-two">
                <img src="assets/img/feature/one/feature-shape-1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tp-feature-shape">
        <div class="tp-feature-shape-one w-img">
          <img src="assets/img/feature/one/features-bg.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;

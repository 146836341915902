import React from "react";
import { AppConstant } from "../../AppConstant";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div class="footer-area">
        <div
          class="tp-footer-top pt-115 pb-70 theme-bg"
          data-background="assets/img/footer/one/footer-bg-1.jpg"
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="tp-footer-widget footer-col-1 mb-40">
                  <div class="tp-footer-widget-logo mb-15">
                    {/* <a href="/">
                      <img src="assets/img/logo/AEON-logo.png" alt="fw-logo" />
                    </a> */}
                  </div>
                  <div class="tp-footer-widget-content">
                    {/* <p>
                      Desires to obtain pain of itself, because it is pain, but
                      occasionally circum
                    </p> */}
                    <div class="tp-footer-widget-content-list">
                      <div class="tp-footer-widget-content-list-item">
                        <i class="fa-solid fa-square-phone"></i>
                        <a href={`tel:${AppConstant.CALL.number}`}>
                          (+91) {AppConstant.CALL.number}
                        </a>
                      </div>
                      <div class="tp-footer-widget-content-list-item">
                        <i class="fa-solid fa-envelope"></i>
                        <a href={`mailto:${AppConstant.EMAIL.id}`}>
                          <span>{AppConstant.EMAIL.title}</span>
                        </a>
                      </div>
                      <div class="tp-footer-widget-content-list-item">
                        <i class="fa-solid fa-location-dot"></i>
                        <a href="javascript:void(0);">Vadodara,Gujarat,India</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="tp-footer-widget tp-footer-col-2 mb-40">
                  <h4 class="tp-footer-widget-title mb-30">Our Navigation</h4>
                  <div class="tp-footer-widget-link">
                    <ul>
                      <li>
                        <Link to="about">
                          <i class="fa-sharp fa-solid fa-plus"></i> About
                          MetaFox
                        </Link>
                      </li>
                      {/* <li>
                        <a href="javascript:void(0);">
                          <i class="fa-sharp fa-solid fa-plus"></i> Meet the
                          Team
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i class="fa-sharp fa-solid fa-plus"></i> News & Media
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i class="fa-sharp fa-solid fa-plus"></i> Our Projects
                        </a>
                      </li> */}
                      <li>
                        <Link to="contact">
                          <i class="fa-sharp fa-solid fa-plus"></i> Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="tp-footer-widget tp-footer-col-3 mb-40">
                  <h4 class="tp-footer-widget-title mb-40">Gallery</h4>
                  <div class="tp-footer-widget-gallery">
                    <div class="tp-footer-widget-gallery-all">
                      <div class="tp-footer-widget-gallery-single-item">
                        <a
                          href="javascript:void(0);"
                          class="tp-footer-widget-gallery-item"
                        >
                          <div class="tp-footer-widget-gallery-thumb">
                            <img
                              src="assets/img/footer/one/gallery/fw-g-1.jpg"
                              alt=""
                            />
                            <div class="tp-footer-widget-gallery-social">
                              <i class="fa-brands fa-linkedin-in"></i>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="tp-footer-widget-gallery-single-item">
                        <a
                          href="javascript:void(0);"
                          class="tp-footer-widget-gallery-item"
                        >
                          <div class="tp-footer-widget-gallery-thumb">
                            <img
                              src="assets/img/footer/one/gallery/fw-g-2.jpg"
                              alt=""
                            />
                            <div class="tp-footer-widget-gallery-social">
                              <i class="fa-brands fa-linkedin-in"></i>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="tp-footer-widget-gallery-single-item">
                        <a
                          href="javascript:void(0);"
                          class="tp-footer-widget-gallery-item"
                        >
                          <div class="tp-footer-widget-gallery-thumb">
                            <img
                              src="assets/img/footer/one/gallery/fw-g-3.jpg"
                              alt=""
                            />
                            <div class="tp-footer-widget-gallery-social">
                              <i class="fa-brands fa-linkedin-in"></i>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="tp-footer-widget-gallery-single-item">
                        <a
                          href="javascript:void(0);"
                          class="tp-footer-widget-gallery-item"
                        >
                          <div class="tp-footer-widget-gallery-thumb">
                            <img
                              src="assets/img/footer/one/gallery/fw-g-4.jpg"
                              alt=""
                            />
                            <div class="tp-footer-widget-gallery-social">
                              <i class="fa-brands fa-linkedin-in"></i>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="tp-footer-widget-gallery-single-item">
                        <a
                          href="javascript:void(0);"
                          class="tp-footer-widget-gallery-item"
                        >
                          <div class="tp-footer-widget-gallery-thumb">
                            <img
                              src="assets/img/footer/one/gallery/fw-g-5.jpg"
                              alt=""
                            />
                            <div class="tp-footer-widget-gallery-social">
                              <i class="fa-brands fa-linkedin-in"></i>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="tp-footer-widget-gallery-single-item">
                        <a
                          href="javascript:void(0);"
                          class="tp-footer-widget-gallery-item"
                        >
                          <div class="tp-footer-widget-gallery-thumb">
                            <img
                              src="assets/img/footer/one/gallery/fw-g-6.jpg"
                              alt=""
                            />
                            <div class="tp-footer-widget-gallery-social">
                              <i class="fa-brands fa-linkedin-in"></i>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="tp-footer-widget tp-footer-col-4 mb-40">
                  <h4 class="tp-footer-widget-title mb-20">Subscribe</h4>
                  <div class="tp-footer-widget-newsletter-wrap">
                    {/* <form action="javascript:void(0);">
                      <div class="tp-footer-widget-newsletter-wrapper">
                        <div class="tp-footer-widget-newsletter-input">
                          <input
                            type="email"
                            placeholder="Get News & Updates"
                          />
                        </div>
                        <div class="tp-footer-widget-newsletter-submit">
                          <button>
                            <i class="fa-regular fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </form> */}
                    <div class="tp-footer-widget-newsletter-content">
                      <p>
                        Our expertise, as well as our passion for web design,
                        that take us to next generation.
                      </p>
                      <div class="tp-footer-widget-newsletter-social">
                        <a href="javascript:void(0);">
                          <i class="fa-brands fa-facebook-f"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/metafox__/"
                          target="_blank"
                        >
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                        <a
                          href="https://in.linkedin.com/company/meta-fox"
                          target="_blank"
                        >
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="javascript:void(0);">
                          <i class="fa-brands fa-twitter"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tp-footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 text-center">
                <div class="tp-footer-copyright">
                  <span>
                    Copyright © 2023&nbsp;
                    <a target="_blank" href="/">
                      MetaFox&nbsp;
                    </a>
                    by All Rights Reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

const CtaAreaSection = () => {
  return (
    <section
      class="cta-area tp-cta-three-bg"
      style={{background:'url(assets/img/bg/cta-2-bg-1.jpg)'}}
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-1 col-lg-2">
            <div
              class="tp-cta-three-icon wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <i class="flaticon-solution"></i>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6">
            <div
              class="tp-cta-three-content wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <h4 class="tp-cta-three-title">
                MetaFox Always Ready For Solve Your Problem
              </h4>
            </div>
          </div>
          {/* <div class="offset-xl-4 col-xl-3 col-lg-4">
            <div
              class="tp-cta-three-btn wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <a href="javascript:void(0);" class="tp-btn">
                Discover More
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default CtaAreaSection;

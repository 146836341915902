import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Header from './header/Header'
import Footer from './footer/Footer'
import AeonRoutes from './routes/Router'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollTop from './Hooks/Scroll-Top/ScrollTop'

const Layout = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <BrowserRouter>
    <div>
      <ScrollTop>
        <Header />
        <AeonRoutes />
        <Footer />
      </ScrollTop>
    </div>
  </BrowserRouter>
  )
}

export default Layout
import React from "react";

const OffersSection = () => {
  return (
    <section
      className="offering-area tp-offer-bg pb-80 pt-120"
      style={{background:'url(assets/img/bg/offer-bg-1.jpg)'}}
    >
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-7">
            <div className="tp-section tp-section-white-two mb-80">
              <span className="tp-section-sub-title">What We’re Offering</span>
              <h4 className="tp-section-title">
                We Provide Exclusive Service <br /> For Your Business
              </h4>
            </div>
          </div>
          {/* <div className="col-lg-5">
            <div className="tp-section-title-wrapper mb-80">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. quis
                nostrud exercitation ullamco laboris
              </p>
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div
              className="tp-offer-item mb-40 wow bounceIn"
              data-wow-duration=".6s"
              data-wow-delay=".6s"
            >
              <div className="tp-offer-item-icon">
                <i className="flaticon-ux-design"></i>
              </div>
              <div className="tp-offer-item-content">
                <h4 className="tp-offer-item-title under-line-white">
                  <a href="services-details.html">Website Development</a>
                </h4>
                <p>We Create unique & custom website designing as per the nature of business and reponsive for better user experience.</p>
              </div>
              {/* <div className="tp-offer-item-btn">
                <a href="services-details.html">
                  Read More <i className="fa-regular fa-arrow-right"></i>
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div
              className="tp-offer-item mb-40 wow bounceIn"
              data-wow-duration=".5s"
              data-wow-delay=".5s"
            >
              <div className="tp-offer-item-icon">
                <i className="flaticon-ux-design"></i>
              </div>
              <div className="tp-offer-item-content">
                <h4 className="tp-offer-item-title under-line-white">
                  <a href="services-details.html">Ui/Ux Designing</a>
                </h4>
                <p>Our team of designers aims to create designs that not only look great but add user experience.</p>
              </div>
              {/* <div className="tp-offer-item-btn">
                <a href="services-details.html">
                  Read More <i className="fa-regular fa-arrow-right"></i>
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div
              className="tp-offer-item mb-40 wow bounceIn"
              data-wow-duration=".5s"
              data-wow-delay=".5s"
            >
              <div className="tp-offer-item-icon">
                <i className="flaticon-ux-design"></i>
              </div>
              <div className="tp-offer-item-content">
                <h4 className="tp-offer-item-title under-line-white">
                  <a href="services-details.html">Digital Marketing</a>
                </h4>
                <p>Our experts will help you create a useful business model to boost your competitiveness and help you become the industry leader.</p>
              </div>
              {/* <div className="tp-offer-item-btn">
                <a href="services-details.html">
                  Read More <i className="fa-regular fa-arrow-right"></i>
                </a>
              </div> */}
            </div>
          </div>
          {/* <div className="col-xl-3 col-lg-4 col-md-6">
            <div
              className="tp-offer-item mb-40 wow bounceIn"
              data-wow-duration=".6s"
              data-wow-delay=".6s"
            >
              <div className="tp-offer-item-icon">
                <i className="flaticon-ux-design"></i>
              </div>
              <div className="tp-offer-item-content">
                <h4 className="tp-offer-item-title under-line-white">
                  <a href="services-details.html">Data Analysis</a>
                </h4>
                <p>Donec suscipit ante ipsum. Donec convallis quality torto</p>
              </div>
              <div className="tp-offer-item-btn">
                <a href="services-details.html">
                  Read More <i className="fa-regular fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default OffersSection;

import React from "react";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <section className="about-area pt-115 tp-about-3 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="tp-about-3-wrap">
              <div
                className="tp-about-3-thumb-1 tp-thumb-common fix mb-30 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div className="tp-thumb-common-overlay wow"></div>
                <img src="assets/img/about/three/about-3-thumb-1.jpg" alt="" />
              </div>
              <div
                className="tp-about-3-thumb-2 wow fadeInLeft tp-thumb-common fix"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div className="tp-thumb-common-overlay wow"></div>
                <img src="assets/img/about/three/about-3-thumb-2.jpg" alt="" />
              </div>
              <div className="tp-about-3-shape-1">
                <img src="assets/img/about/three/about-3-shape-1.jpg" alt="" />
              </div>
              <div className="tp-about-3-project">
                <span>Project Completed</span>
                <h3 className="tp-about-3-project-title">17+</h3>
              </div>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-6">
            <div
              className="tp-about-3-wrapper mb-50 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              <div className="tp-section tp-section-3 mb-50">
                <span className="tp-section-sub-title">Welcome to MetaFox</span>
                <h4 className="tp-section-title">
                  Easy Solutions For Your <br /> IT Problems
                </h4>
                <div className="tp-section-title-wrapper">
                  <p>
                    One of the leading Website development  Since 2015, We had developed over 20+ sensational websites for more than 15+ clients across the globe, gaining an advantageous reputation for excellence within the technology and art industry. 
                    <br />Whether you are a start-up or a well-established business; we offer innovative, inspirational, and cost-effective websites development and a full range of associated services, aligned with your business goals.

                  </p>
                </div>
              </div>
              <div className="tp-about-3-item mb-35">
                <div className="tp-about-3-item-icon">
                  <i className="flaticon-solution"></i>
                </div>
                <div className="tp-about-3-item-content">
                  <h4 className="tp-about-3-item-title">
                    Deliver awesome idea
                  </h4>
                  <p>
                    That power your business
                  </p>
                </div>
              </div>
              <div className="tp-about-3-list mb-45">
                <ul>
                  <li>
                    <span>
                      <img src="assets/img/icon/check-icon-2.svg" alt="" />
                    </span>
                    Essentially concentrated on customized solutions
                  </li>
                  <li>
                    <span>
                      <img src="assets/img/icon/check-icon-2.svg" alt="" />
                    </span>
                    We never compromise with the quality of our services
                  </li>
                </ul>
              </div>
              <div className="tp-about-3-details  d-flex align-items-center">
                <div className="tp-about-3-btn">
                  <Link className="tp-btn"  to='/about'>Learn More</Link>
                </div>
                <div className="tp-about-3-customar d-flex align-items-end">
                  <h4 className="tp-about-3-customar-title">15+</h4>
                  <p>
                    Satisfied <br /> Clients
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

import React from "react";
import SideService from "./SideService";
import TopScreen from "../../../common/TopScreen/TopScreen";
import { breadcrumbDetailsWebDevelopment } from "../../../../Utilites";

const WebDevelopment = () => {
  return (
    <section>
      <TopScreen breadcrumbDetails={breadcrumbDetailsWebDevelopment} />
      <div class="services-area pt-115 pb-80">
        <div class="container">
          <div class="row">
            <SideService />
            <div class="col-lg-8">
              <div class="tp-services-details-wrapper ml-30">
                <div class="tp-services-details-main-thumb mb-30 w-img">
                  <img
                    src="assets/img/services/details/services-details-thumb-1.jpg"
                    alt=""
                  />
                </div>
                <h4 class="tp-services-details-title mb-20">Web Development</h4>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book
                </p>
                <div class="tp-services-details-check d-flex align-items-center mt-50">
                  <div class="tp-services-details-check-item d-flex align-items-center mb-55">
                    <i class="fa-light fa-check"></i>
                    <span>
                      Neque porro est qui dolorem ipsum quaed inventore
                      veritatis
                    </span>
                  </div>
                  <div class="tp-services-details-check-item d-flex align-items-center ml-55 mb-55">
                    <i class="fa-light fa-check"></i>
                    <span>
                      Neque porro est qui dolorem ipsum quaed inventore
                      veritatis
                    </span>
                  </div>
                </div>
                <div class="tp-services-details-faq">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="tp-services-details-faq-item d-flex align-items-center mb-50">
                        <div class="tp-services-details-faq-item-icon">
                          <i class="flaticon-medal"></i>
                        </div>
                        <div class="tp-services-details-faq-item-content">
                          <h4 class="tp-services-details-faq-title">
                            Why IT Solution?
                          </h4>
                          <span>We always try to give best services</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="tp-services-details-faq-item d-flex align-items-center mb-50">
                        <div class="tp-services-details-faq-item-icon">
                          <i class="flaticon-medal"></i>
                        </div>
                        <div class="tp-services-details-faq-item-content">
                          <h4 class="tp-services-details-faq-title">
                            Best Solution
                          </h4>
                          <span>We always try to give best services</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="tp-services-details-title">Web Development</h4>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book
                </p>
                <div class="tp-services-details-item-wrap mt-60">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="tp-services-details-item-wrapper mb-40">
                        <div class="tp-services-details-item-icon">
                          <i class="flaticon-coding"></i>
                        </div>
                        <h4 class="tp-services-details-item-title">
                          Website Development
                        </h4>
                        <p>Lorem ipsum dolor sit amet do</p>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="tp-services-details-item-wrapper mb-40">
                        <div class="tp-services-details-item-icon">
                          <i class="flaticon-coding"></i>
                        </div>
                        <h4 class="tp-services-details-item-title">
                          Ui/Ux <br /> Designing
                        </h4>
                        <p>Lorem ipsum dolor sit amet do</p>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="tp-services-details-item-wrapper mb-40">
                        <div class="tp-services-details-item-icon">
                          <i class="flaticon-coding"></i>
                        </div>
                        <h4 class="tp-services-details-item-title">
                          Data <br /> Analysis
                        </h4>
                        <p>Lorem ipsum dolor sit amet do</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebDevelopment;

import React from "react";
import { Link } from "react-router-dom";

const TopScreen = ({breadcrumbDetails}) => {
  return (
    <section class="breadcrumb-area breadcrumb-wrap">
      <div
        class="breadcrumb-bg"
        style={{background:'url(assets/img/breadcrumb/breadcrumb-bg-1.jpg)'}}
      ></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="tpbreadcrumb">
              <div class="breadcrumb-link mb-15">
                <span class="breadcrumb-item-active">
                  <Link to={'/'}>Home</Link>
                </span>
                <span> / {breadcrumbDetails.activeElement}</span>
              </div>
              <h2 class="breadcrumb-title">{breadcrumbDetails.title}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-shape">
        <div
          class="breadcrumb-shape-1"
          data-aos="fade-left"
          data-aos-duration="1800"
          data-aos-delay="400"
        >
          <img src="assets/img/breadcrumb/breadcrumb-shape-1.png" alt="" />
        </div>
        <div
          class="breadcrumb-shape-4"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <img src="assets/img/breadcrumb/breadcrumb-shape-3.png" alt="" />
        </div>
        <div
          class="breadcrumb-shape-5"
          data-aos="fade-left"
          data-aos-duration="1400"
          data-aos-delay="300"
        >
          <img src="assets/img/breadcrumb/breadcrumb-shape-2.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default TopScreen;

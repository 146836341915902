import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section class="error-area tp-erorr-wrap">
      <div
        class="tp-erorr-bg"
        style={{ background: "url(assets/img/thumbs/error-bg-1.jpg)" }}
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="tp-erorr-content text-center">
              <h4 class="tp-erorr-title">
                4<span>0</span>4
              </h4>
              <span>Ooops....</span>
              <p>We are sorry, something went wrong</p>
              <Link class="tp-btn" to="/">
                Go Home <i class="fa-light fa-arrow-right ml-5"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;

import React from 'react'

const FeatureSection = () => {
  return (
    <section className="feature-area tp-feature-3-bg" data-background="assets/img/feature/three/feature-3-bg-1.jpg">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="tp-feature-3-wrapper d-block d-md-flex align-items-center justify-content-between">
                        <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-worldwide"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">Global Networking</h4>
                              <span>G</span>
                           </div>
                        </div>
                        <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-consultant"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">Business Consultation</h4>
                              <span>B</span>
                           </div>
                        </div>
                        <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".4s" data-wow-delay=".4s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-data-visualization"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">Website Development</h4>
                              <span>W</span>
                           </div>
                        </div>
                        <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-ux-design"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">UI/UX Design</h4>
                              <span>u</span>
                           </div>
                        </div>
                        <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-technical-support"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">Support Management</h4>
                              <span>S</span>
                           </div>
                        </div>
                        <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-worldwide"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">Digital Marketing</h4>
                              <span>G</span>
                           </div>
                        </div>
                        {/* <div className="tp-feature-3-item text-center mb-60 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                           <div className="tp-feature-3-item-icon">
                              <i className="flaticon-consultant"></i>
                           </div>
                           <div className="tp-feature-3-item-content">
                              <h4 className="tp-feature-3-item-title">Business Consultation</h4>
                              <span>B</span>
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div className="tp-feature-3-details text-center">
                        <span>We will provide IT Technology services built specifically for your business.</span>
                     </div>
                  </div>
               </div>
            </div>
         </section>
  )
}

export default FeatureSection
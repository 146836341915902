import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const ScrollTop = ({children}) => {
    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo({
          top: 10,
          left: 0,
          behavior: "smooth"
        })
    },[pathname])

  return (
    <>{children}</>
  )
}

export default ScrollTop;
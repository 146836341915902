import React from "react";

const BanifitSection = () => {
  return (
    <section class="video-area tp-benifits-inner pt-120">
      <div class="container">
        <div class="row">
          <div class="col-xxl-7 col-xl-6 col-lg-6 order-2 order-lg-1">
            <div class="tp-benifits-5-thumb">
              <div class="tp-benifits-5-thumb-1">
                <img src="assets/img/about/five/about-5-thumb-1.jpg" alt="" />
              </div>
              <div class="tp-benifits-5-thumb-2 overlay-anim">
                <div class="tp-thumb-common-overlay wow"></div>
                <img src="assets/img/about/five/about-5-thumb-2.jpg" alt="" />
              </div>
              <div class="tp-benifits-5-wrap">
                <div class="tp-benifits-5-contact">
                  <div class="tp-benifits-5-contact-icon">
                    <i class="fa-solid fa-phone-flip"></i>
                  </div>
                  <div class="tp-benifits-5-contact-content">
                    <span>Call Anytime</span>
                    <a href="tel:+916352118120">+91 6352118120</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-5 col-xl-6 col-lg-6 order-1 order-lg-2">
            <div
              class="tp-video-two-wrapper p-relative pb-120 wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay=".6s"
            >
              <div class="tp-section mb-35">
                {/* <span class="tp-section-sub-title">Check Our Benifits</span> */}
                <h4 class="tp-section-title">
                  We're Partner of Your Innovations
                </h4>
                <div class="tp-section-title-wrapper">
                  <p>
                    With new technologies coming into the forefront each day, it is very important that you remain high on the surge in this competitive market. <br />Our specialized and expert team helps our clients to maintain this dignity and distinctiveness in this era of globalization.
                  </p>
                </div>
                <div class="tp-section-title-info d-flex align-items-center mt-30">
                  <div class="tp-section-title-info-icon">
                    <i class="flaticon-project"></i>
                  </div>
                  <div class="tp-section-title-info-text">
                    <span>
                      We aim at to be an unparalleled and an ace in online presence.
                    </span>
                  </div>
                </div>
              </div>
              <div class="tp-video-two-list mb-50">
                <ul>
                  <li>
                    <span>
                      <i class="fa-regular fa-circle-check"></i>
                    </span>
                    We fixate to stay spruced up with the latest enhanced technologies.
                  </li>
                  <li>
                    <span>
                      <i class="fa-regular fa-circle-check"></i>
                    </span>
                    We offer feasible and cost-effective solutions.
                  </li>
                </ul>
              </div>
              <div class="tp-about-details d-flex align-items-center flex-wrap">
                {/* <div class="tp-about-btn mr-30 mb-10">
                  <a class="tp-btn" href="about.html">
                    Discover More
                  </a>
                </div> */}
                {/* <div class="tp-about-avatar d-flex align-items-center mb-10">
                  <div class="tp-about-avatar-thumb">
                    <img src="assets/img/about/one/avatar-1.png" alt="" />
                  </div>
                  <div class="tp-about-avatar-info">
                    <h5 class="tp-about-avatar-title">Harry Newman</h5>
                    <span>Co Founder</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BanifitSection;

import React from "react";
import TopScreen from '../../common/TopScreen/TopScreen'
import ServiceSteps from "./Service-Steps/ServiceSteps";
import { breadcrumbDetailsServices, metaDetailsServices } from '../../../Utilites'
import MetaTagComponent from "../../common/Meta/MetaTagComponent";
import IndustryWork from "./Industry-work/IndustryWork";

import ManagementSection from "./ManagementSection";
const AeonServices = () => {
  return (
    <section>
      <MetaTagComponent metaDetails={metaDetailsServices}/>
      <TopScreen breadcrumbDetails={breadcrumbDetailsServices}/>
      <ManagementSection/>

      <div class="container">
        <div class="row align-items-end">
          <div class="col-xl-5 col-lg-6">
            <div
              class="tp-section tp-section-two mb-50"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <span class="tp-section-sub-title">Service We Are Offering</span>
              <h4 class="tp-section-title">
                We Provide Our Clients Best IT Service
              </h4>
            </div>
          </div>
          <div class="offset-xl-2 col-xl-5 col-lg-6">
            <div
              class="tp-section mb-40"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <div class="tp-section-title-wrapper">
                <p>
                  We aim to strive for a cutting edge website to establish higher audience engagement and conversion rates. we will help you to create astonishingly design and effective site that form real leads and revenue.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ServiceSteps/>
      {/* <IndustryWork/> */}
    </section>
  );
};

export default AeonServices;

import React from "react";

const ManagementSection = () => {
  return (
    <section class="management-area tp-management-position pb-60">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-xl-6 col-lg-6">
            <div class="tp-management-thumb-wrap p-relative">
              <div
                class="tp-management-thumb overlay-anim tp-thumb-common fix w-img mb-60 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="tp-thumb-common-overlay wow"></div>
                <img src="assets/img/thumbs/management-thumb-1.jpg" alt="" />
              </div>
              <div class="tp-management-shape-1">
                <img src="assets/img/shape/management-shape-1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-xl-5 offset-xl-1 col-lg-6">
            <div
              class="tp-management-wrap pb-60 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay=".4s"
            >
              <div class="tp-section mb-40">
                {/* <span class="tp-section-sub-title">Tech Management</span> */}
                <h4 class="tp-section-title">
                  Let’s Build Your Success With MetaFox
                </h4>
                <div class="tp-section-title-wrapper">
                  <p>
                    We provide a custom web design and development service that not only help you get your website done but makes your website one of a kind.
                  </p>
                  <span>
                    We design your websites that are “Elegant” your website is the face of your business and it conveys your Brands Message.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManagementSection;

export const metaDetailsHome = {
  title: "MetaFox | web design and development company",
  description:
    "Experience the expertise of a leading web design and development company, dedicated to delivering high-quality and innovative digital solutions ",
  keyWord: "MetaFox",
};

export const metaDetailsAbout = {
  title: "About Us | Best web design and development company",
  description:
    "Aeon Tech Delivering top-notch web design and development solutions tailored to meet your unique business requirements",
  keyWord: "MetaFox",
};

export const metaDetailsServices = {
  title: "MetaFox | One Place Solutions for Digital Web Services",
  description:
    "One stop solutions for your web design, mobile application development, branding and custom build website dev services. Contact us today for a custom quote.",
  keyWord: "MetaFox",
};

export const breadcrumbDetailsAbout = {
  activeElement:'About',
  title:'About Us'
}
export const breadcrumbDetailsServices = {
  activeElement:'Services',
  title:'Our Services'
}
export const breadcrumbDetailsContactus = {
  activeElement:'Contact Us',
  title:'Contact Us'
}
export const breadcrumbDetailsWebDevelopment = {
  activeElement:'Service Details',
  title:'Service Details'
}

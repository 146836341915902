import React from "react";

const AeonPartners = () => {
  return (
    <section class="brand-area">
      <div class="container">
        <div class="row gx-0">
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-wrapper mb-40">
              <h4 class="tp-brand-title">
                Business Company <br /> Growing with <br />
                Our
                <span> MetaFox</span> <br />Solution
              </h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-1.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-2.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-3.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-4.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-5.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-6.png" alt="" />
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="tp-brand-item">
              <img src="assets/img/brand/one/brand-logo-7.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AeonPartners;

import React from "react";
import { Link } from "react-router-dom";

const CtaSection = () => {
  return (
    <section class="cta-area tp-cta-two pt-120 pb-120">
      <div
        class="tp-cta-two-bg"
        style={{background:'url(assets/img/bg/cta-2-bg.jpg)'}}
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tp-cta-two-wrapper text-center">
              <div
                class="tp-cta-two-icon wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <i class="flaticon-technology"></i>
              </div>
              <h4
                class="tp-cta-two-title wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".6s"
              >
                IT Solutions & Services <br /> at your Fingertips
              </h4>
              <div
                class="tp-cta-two-btn wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".7s"
              >
                <Link className="tp-btn" to="about">Know More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;

import "./App.css";
import Layout from "./components/Layout";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function App() {
  return (
    <div className="">
      <Layout/>
    </div>
  );
}

export default App;

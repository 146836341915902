import React from "react";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const SliderSection = () => {
  return (
    <Swiper
      modules={[Pagination,Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      pagination={{  el: '.tp-slider-dot',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + '<button>' + '0' + (index + 1) + '</button>' + '</span>';
      },
    }}
      autoplay={{delay:5000}}
      className="tp-slider-active"
    >
      <SwiperSlide>
        <div className="tp-slider-wrapper">
          <div
            className="tp-slider-bg"
            style={{
              background: "url(assets/img/slider/slider-3-thumb-2.jpg)",
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tp-slider-content">
                  <span className="tp-slider-sub-title">
                    Solutions for your businesses
                  </span>
                  <h2 className="tp-slider-title">
                    <span>Inno</span>vating <br /> Tech Solution
                  </h2>
                  <div className="tp-slider-btn">
                    <Link className="tp-btn" to="/about">
                      Discover More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tp-slider-shape-1">
            <img src="assets/img/slider/slider-shape-1.png" alt="" />
          </div>
          <div className="tp-slider-shape-2">
            <img src="assets/img/slider/slider-shape-2.png" alt="" />
          </div>
          <div className="tp-slider-shape-3">
            <img src="assets/img/slider/slider-shape-3.png" alt="" />
          </div>
          <div className="tp-slider-shape-4">
            <img src="assets/img/slider/slider-shape-4.png" alt="" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="tp-slider-wrapper">
          <div
            className="tp-slider-bg"
            style={{
              background: "url(assets/img/slider/slider-3-thumb-1.jpg)",
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tp-slider-content">
                  <span className="tp-slider-sub-title">Build robust</span>
                  <h2 className="tp-slider-title">Online Presence</h2>
                  <div className="tp-slider-btn">
                    <Link className="tp-btn" to="/services">
                      Explore Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tp-slider-shape-1">
            <img src="assets/img/slider/slider-shape-1.png" alt="" />
          </div>
          <div className="tp-slider-shape-2">
            <img src="assets/img/slider/slider-shape-2.png" alt="" />
          </div>
          <div className="tp-slider-shape-3">
            <img src="assets/img/slider/slider-shape-3.png" alt="" />
          </div>
          <div className="tp-slider-shape-4">
            <img src="assets/img/slider/slider-shape-4.png" alt="" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="tp-slider-wrapper">
          <div
            className="tp-slider-bg"
            style={{
              background: "url(assets/img/slider/slider-3-thumb-3.jpg)",
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tp-slider-content">
                  <span className="tp-slider-sub-title">accelerate your</span>
                  <h2 className="tp-slider-title">business To next Level</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="tp-slider-shape-1">
            <img src="assets/img/slider/slider-shape-1.png" alt="" />
          </div>
          <div className="tp-slider-shape-2">
            <img src="assets/img/slider/slider-shape-2.png" alt="" />
          </div>
          <div className="tp-slider-shape-3">
            <img src="assets/img/slider/slider-shape-3.png" alt="" />
          </div>
          <div className="tp-slider-shape-4">
            <img src="assets/img/slider/slider-shape-4.png" alt="" />
          </div>
        </div>
      </SwiperSlide>
      <div className="tp-slider-dot"></div>
    </Swiper>
  );
};

export default SliderSection;
import React from "react";
import FeatureSection from "./Sections/FeatureSection";
import AboutSection from "./Sections/AboutSection";
import OffersSection from "./Sections/OffersSection";
import CtaSection from "./Sections/CtaSection";
import SolutionArea from "./Sections/SolutionArea";
import CounterSection from "./Sections/CounterSection";
import BrandSection from "./Sections/BrandSection";
import SliderSection from "./Sections/SliderSection";
import MetaTagComponent from "../common/Meta/MetaTagComponent";
import { metaDetailsHome } from "../../Utilites";
import AeonPartners from "../common/Aeon-Partners/AeonPartners";

const Home = () => {
  
  return (
    <>
      <MetaTagComponent metaDetails={metaDetailsHome} />
      <main>
        <SliderSection />
        <FeatureSection />
        <AboutSection />
        <OffersSection />
        <CtaSection />
        <SolutionArea />
        <CounterSection />
        {/* <BrandSection /> */}
        <AeonPartners/>
      </main>
    </>
  );
};

export default Home;

import React from 'react'
import TopScreen from '../common/TopScreen/TopScreen'
import BanifitSection from './Sections/BanifitSection'
import FeatureSection from './Sections/FeatureSection'
import CounterSection from './Sections/CounterSection'
import ManagementSection from './Sections/ManagementSection'
import CtaAreaSection from './Sections/CtaAreaSection'
import MetaTagComponent from '../common/Meta/MetaTagComponent'
import { breadcrumbDetailsAbout, metaDetailsAbout } from '../../Utilites'

const About = () => {
  return (
    <div>
      <MetaTagComponent metaDetails={metaDetailsAbout} />
        <TopScreen breadcrumbDetails={breadcrumbDetailsAbout}/>
        <BanifitSection/>
        <FeatureSection/>
        <CounterSection/>
        <ManagementSection/>
        <CtaAreaSection/>
    </div>
  )
}

export default About
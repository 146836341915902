import React from 'react'
import { Helmet } from 'react-helmet'

const MetaTagComponent = ({metaDetails}) => {
  return (
    <Helmet>
        <meta charSet="utf-8"/>
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}/>
        <meta name="keywords" content={metaDetails.keyWord}/>
    </Helmet>
  )
}

export default MetaTagComponent
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import About from "../about/About";
import NotFound from "../not-found/NotFound";
import ContactUs from "../contact-us/ContactUs";
import AeonServices from "../pages/Service/AeonServices";
import WebDevelopment from "../pages/Service/Details-page/WebDevelopment";

const AeonRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<ContactUs />} />
      <Route path="services" element={<AeonServices />} />
      <Route path="webdevelopment" element={<WebDevelopment />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AeonRoutes;

import React from "react";
import { Link } from "react-router-dom";

const MenuLinks = ({closeMobileMenu}) => {
  return (
    <nav id="mobile-menu" className="tp-main-menu-content">
      <ul>
        <li className="has-dropdown">
          <Link to="/" onClick={closeMobileMenu}>Home</Link>
        </li>
        <li className="has-dropdown">
          <Link to="/about" onClick={closeMobileMenu}>About</Link>
        </li>
        <li className="has-dropdown">
          <Link to='/services' onClick={closeMobileMenu}>Service</Link>
          {/* <ul className="tp-submenu">
            <li>
              <a href="javascript:void(0);">Web Design & Development</a>
            </li>
            <li>
              <a href="javascript:void(0);">App Development</a>
            </li>
            <li>
              <a href="javascript:void(0);">Ecommerce Development</a>
            </li>
          </ul> */}
        </li>
        <li>
          <Link to={'/contact'} onClick={closeMobileMenu}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuLinks;

import React from "react";

const SolutionArea = () => {
  return (
    <>
    <section class="cta-area tp-cta-bottom-2">
        <div class="container">
            <div class="tp-cta-bg" style={{background:'url(assets/img/bg/cta-bg-1.jpg)'}}>
                <div class="row align-items-center">
                    <div class="col-xl-5 col-lg-6 col-md-7">
                    <div class="tp-cta-wrapper wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                        <h4 class="tp-cta-title">MetaFox Always Ready For Solve Your Problem</h4>
                    </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-md-5">
                    {/* <div class="tp-cta-btn wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                        <a href="contact.html" class="tp-btn">Discover More</a>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
      <section class="solution-area tp-solution-wrapper">
        <div class="container">
          <div class="row align-items-end">
            <div class="offset-lg-6 col-lg-6">
              <div
                class="tp-solution-wrap ml-40 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="tp-section tp-section-3 mb-50">
                  <span class="tp-section-sub-title">Technology Solution</span>
                  <h4 class="tp-section-title">
                    For Your Specific Industry We Have Smart Idea
                  </h4>
                  <div class="tp-section-title-wrapper">
                    <p>
                      Our team comprises experts in website development, web application development, UX/UI design, e-commerce solutions, and more. 
                      We bring a wealth of knowledge and experience to every project we undertake.
                    </p>
                  </div>
                </div>
                <div class="tp-solution-info mb-50">
                  <ul>
                    <li>
                      <div class="tp-solution-info-item d-flex align-items-center">
                        <div class="tp-solution-info-icon">
                          <i class="flaticon-technical-support"></i>
                        </div>
                        <div class="tp-solution-info-content">
                          <h4 class="tp-solution-info-title">
                            Robust <br /> Solution
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="tp-solution-info-item d-flex align-items-center">
                        <div class="tp-solution-info-icon">
                          <i class="flaticon-optimization"></i>
                        </div>
                        <div class="tp-solution-info-content">
                          <h4 class="tp-solution-info-title">
                            24/7 Technical <br /> Support 
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tp-solution-bg"
          style={{background:'url(assets/img/bg/solution-bg-1.jpg)'}}
        >
        </div>
        <div class="tp-solution-shape-1">
          <img src="assets/img/shape/solution-shape-1.png" alt="" />
        </div>
      </section>
    </>
  );
};

export default SolutionArea;

import React from "react";
import MenuLinks from "../MenuLinks";

const MobileMenu = ({ setIsMobMenuOpened, isMobMenuOpened }) => {
  const closeMobileMenu = () => {
    setIsMobMenuOpened(false);
  };

  return (
    <div
      className={`offcanvas__area ${isMobMenuOpened ? "offcanvas-opened" : ""}`}
    >
      <div className="offcanvas__wrapper">
        <div className="offcanvas__close">
          <button
            className="offcanvas__close-btn offcanvas-close-btn"
            onClick={closeMobileMenu}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="offcanvas__content">
          <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
            <div className="offcanvas__logo logo">
              <a href="index.html">
                <img src="assets/img/logo/Meta_fox.png" style={{ maxWidth: "180px" }} alt="logo" />
              </a>
            </div>
          </div>
          <div className="tp-main-menu-mobile">
            <MenuLinks closeMobileMenu={closeMobileMenu}/>
          </div>
          {/* <div className="offcanvas__btn">
            <a href="contact.html" className="tp-btn">
              Getting Started <i className="fa-regular fa-chevron-right"></i>
            </a>
          </div> */}
          {/* <div className="side-info-contact">
            <span>we are here</span>
            <p>
              1489 Langley Ave <br /> Grand Forks Afb, North.
            </p>
          </div> */}
          {/* <div className="side-info-social">
            <a href="#">
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a href="#">
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a href="#">
              <i className="fa-brands fa-pinterest-p"></i>
            </a>
            <a href="#">
              <i className="fa-solid fa-paper-plane"></i>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
